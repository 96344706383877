import { Box, useTheme } from "@mui/material";
import Modal from "react-modal";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import { tokens } from "../../theme";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import "./../Style.css";
import {
  getPatientFile,
  AddImage,
  AddFiles,
  deletePatientFile,
} from "../../Redux/Patient/PatientActions";
import { useLocation, useParams } from 'react-router-dom';
import axios from "axios";
const File = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getpatientFiles, loading } = useSelector((state) => state.Patient);
  useEffect(() => {
    dispatch(getPatientFile(id));
  }, []);
  const [data, setData] = useState([]);
  const [Id, setId] = useState(null);
  const[fileName,setFileName]=useState(null);
  const location = useLocation();
  console.log("location",location);
  //   const receivedData = location.state;
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');

  let firstName = '';
  let lastName = '';

  if (name) {
    const nameParts = name.split(' ');
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(' ');
  }
  useEffect(() => {
    if (getpatientFiles) {
      setData(getpatientFiles?.data?.files);
    }
  }, [getpatientFiles]);
  console.log(data);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log(data);
  const downloadFile = async ( fileName) => {
    try {
      axios({
        url: process.env.REACT_APP_API + "/api/patients/download/"+ id + "/" + fileName,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
    } catch (error) {
      console.error('Error downloading file:', error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const [isSidebar, setIsSidebar] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [file, setFile] = useState(null);
  const [Images, setImages] = useState(null);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const opendeleteModal = () => {
    setDeleteModal(true);
  };
  const closedeleteModal = () => {
    setDeleteModal(false);
  };

  const openModal1 = () => {
    setModalIsOpen1(true);
  }
  const closeModal1 = () => {
    setModalIsOpen1(false);
  }
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFile((prevFiles) => prevFiles ? [...prevFiles, ...selectedFiles] : selectedFiles);
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages((prevImages) => prevImages ? [...prevImages, ...selectedImages] : selectedImages);
  };

  const handleUpload = () => {
      dispatch(AddFiles(id, file));
      closeModal();
      setFile(null);
  };
  const handleUpload1 = () => {
    dispatch(AddImage(id, Images));
    closeModal1();
    setImages(null);
  };
  const columns = [
    {
      field: "File Name",
      headerName: "File Name",
      flex: 1,
       renderCell: (record) => {
        return (
          <Fragment>
            <div
              variant="outlined"
              color="primary"

            >
  {record.row.name}           </div>
          </Fragment>
        );
      },
    },
    {
      field: "Image",
      headerName: "Image",
      flex: 1,
       renderCell: (record) => {
        return (
          <Fragment>
         <img src={process.env.REACT_APP_API + record.row.url} alt="file" style={{width:"100px",height:"100px"}}/>
          </Fragment>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              variant="outlined"
              color="primary"
              onClick={() => {
                downloadFile(record.row.name)
              }
              }
            >
              download          </button>
              &nbsp; &nbsp;
              <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                setFileName(record.row.name);
                opendeleteModal();
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },

  ];
  const deletehandle = (id, fileName) => {
    dispatch(deletePatientFile(id, fileName));
    closedeleteModal();
  }


 
  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
        <Modal
            isOpen={modalIsOpen1}
            onRequestClose={closeModal1}
            shouldCloseOnOverlayClick={false}
            contentLabel="add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add Images</h2>
              <input type="file" accept="image/*" multiple onChange={handleImageChange} />
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal1} className="buttoninmodal">
                Close
              </button>
              <button
                 onClick={() => handleUpload1()}
                className="buttoninmodal1"
              >
                Add Image
              </button>
            </div>
          </Modal>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add File</h2>
              <input type="file" accept="application/*" multiple onChange={handleFileChange} />
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button
                 onClick={() => handleUpload()}
                className="buttoninmodal1"
              >
                Add File
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={deleteModal}
            onRequestClose={closedeleteModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Delete File</h2>
              <h5>Are You Sure You Want To Delete This File ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closedeleteModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deletehandle(id,fileName)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          <Header           

            title={firstName + " " + lastName  +"'s" +" Files"}
            buttonText="Add File"
            onopen={openModal}
            buttonText1="Add Image"
            onopen1={openModal1}
            
          />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
               
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default File;
