import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  getClinic,
  deleteClinic,
  updateClinic,
  AddClinic,
} from "../../Redux/Clinic/ClinicActions";
import { getDistrict } from "../../Redux/District/DistrictActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import PopupModal from "../../components/PopupModal";
import { useFetcher, useNavigate } from "react-router-dom";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";

import isAuth from "../../scenes/Utils/isAuth";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
const Clinic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);
  const { Clinic, loading , error , addclinic , loadingadd } = useSelector((state) => state.Clinic);
  useEffect(() => {
    dispatch(getClinic());
    dispatch(getDistrict());
  }, []);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Clinic) {
      setData(Clinic?.data?.clinics);
    }
  }, [Clinic]);
  const { District } = useSelector((state) => state.District);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [district_id, setDistrictId] = useState("");
  const [name, setName] = useState("");
  const [doctor_name, setDoctorName] = useState("");
  const [doctor_phone_number, setDoctorPhoneNumber] = useState("");
  const [doctor_email, setDoctorEmail] = useState("");
  const [address, setAddress] = useState("");
  const [secretary_name, setSecretaryName] = useState("");
  const [secretary_phone_number, setSecretaryPhoneNumber] = useState("");
  const [secretary_email, setSecretaryEmail] = useState("");
  const [location, setLocation] = useState("");
  const [landline_number, setLandlineNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook_page, setFacebookPage] = useState("");
  const [instagram_page, setInstagramPage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isclicked, setIsclicked] = useState(false);

  const deleteplanhandle = (id) => {
    dispatch(deleteClinic(id));
    NotificationManager.success("Success", "Clinic Deleted Successfully", 5000, () => {});

    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setEmail("");
    setPhoneNumber("");
    setDistrictId("");
    setName("");
    setDoctorName("");
    setDoctorPhoneNumber("");
    setDoctorEmail("");
    setAddress("");
    setSecretaryName("");
    setSecretaryPhoneNumber("");
    setSecretaryEmail("");
    setLocation("");
    setLandlineNumber("");
    setWebsite("");
    setFacebookPage("");
    setInstagramPage("");

  };
  const addplanhandle = (
    email,
    phone_number,
    district_id,
    name,
    doctor_name,
    doctor_phone_number,
    doctor_email,
    address,
    secretary_name,
    secretary_phone_number,
    secretary_email,
    location,
    landline_number,
    website,
    facebook_page,
    instagram_page
  ) => {
    setIsclicked(true);
    dispatch(
      AddClinic(
        email,
        phone_number,
        parseInt(district_id),
        name,
        doctor_name,
        doctor_phone_number,
        doctor_email,
        address,
        secretary_name,
        secretary_phone_number,
        secretary_email,
        location,
        landline_number,
        website,
        facebook_page,
        instagram_page
      )
    );

  };
  useEffect(() => {
    if (error !== null && isclicked === true) {
      NotificationManager.error("Error", error?.message, 5000, () => {});
    }
    else if (addclinic == "success" && isclicked === true) {
      NotificationManager.success("Success", "Clinic Added Successfully", 5000, () => {});
      setaddModalIsOpen(false);
      setaddModalIsOpen(false);
      setedutModalIsOpen(false);
      setEmail("");
      setPhoneNumber("");
      setDistrictId("");
      setName("");
      setDoctorName("");
      setDoctorPhoneNumber("");
      setDoctorEmail("");
      setAddress("");
      setSecretaryName("");
      setSecretaryPhoneNumber("");
      setSecretaryEmail("");
      setLocation("");
      setLandlineNumber("");
      setWebsite("");
      setFacebookPage("");
      setInstagramPage("");
      setIsclicked(false);

    }
  }, [error,addclinic]);

console.log("Clinic",addclinic)

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
    setaddModalIsOpen(false);
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setEmail("");
    setPhoneNumber("");
    setDistrictId("");
    setName("");
    setDoctorName("");
    setDoctorPhoneNumber("");
    setDoctorEmail("");
    setAddress("");
    setSecretaryName("");
    setSecretaryPhoneNumber("");
    setSecretaryEmail("");
    setLocation("");
  };
  const editplanhandle = (
    id,
    email,
    phone_number,
    district_id,
    name,
    doctor_name,
    doctor_phone_number,
    doctor_email,
    address,
    secretary_name,
    secretary_phone_number,
    secretary_email,
    location,
    landline_number,
    website,
    facebook_page,
    instagram_page
  ) => {
    dispatch(
      updateClinic(
        id,
        email,
        phone_number,
        district_id,
        name,
        doctor_name,
        doctor_phone_number,
        doctor_email,
        address,
        secretary_name,
        secretary_phone_number,
        secretary_email,
        location,
        landline_number,
        website,
        facebook_page,
        instagram_page
      )
    );
    setedutModalIsOpen(false);
    setaddModalIsOpen(false);
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setEmail("");
    setPhoneNumber("");
    setDistrictId("");
    setName("");
    setDoctorName("");
    setDoctorPhoneNumber("");
    setDoctorEmail("");
    setAddress("");
    setSecretaryName("");
    setSecretaryPhoneNumber("");
    setSecretaryEmail("");
    setLocation("");
    NotificationManager.success("Success", "Clinic Edited Successfully", 5000, () => {});

  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Email",
      valueGetter: (params) => params.row.user.email,
      width: 200,
    },
    {
      field: "Phone number",
      headerName: "Phone number",
      valueGetter: (params) => params.row.user.phone_number,
      width: 200,
    },
    {
      field: "District",
      headerName: "District",
      valueGetter: (params) => {
        const districtId = params.row.user.district_id;
        const district = District?.data?.districts.find(
          (d) => d.id === districtId
        );
        return district ? district.name : "Unknown District";
      },
      width: 200,
    },

    { field: "name", headerName: "Name", width: 100 },
    { field: "doctor_name", headerName: "Doctor Name", width: 150 },
    {
      field: "doctor_email",
      headerName: "Doctor Email",
      width: 200,
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "doctor_phone_number",
      headerName: "Phone Number",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "secretary_name",
      headerName: "Secretary Name",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
      width: 150,
    },
    {
      field: "secretary_phone_number",
      headerName: "Secretary Phone Number",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
      width: 150,
    },
    {
      field: "secretary_email",
      headerName: "Secretary Email",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "landline_number",
      headerName: "Landline Number",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "website",
      headerName: "Website",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "facebook_page",
      headerName: "Facebook Page",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "instagram_page",
      headerName: "Instagram Page",
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "calendar",
      headerName: "calendar",

      width: 250,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions3"
              onClick={() => {
                navigate(`/calendar/${record.row.id}`);
              }}
            >
              View Calendar
            </button>
          </Fragment>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",

      width: 200,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setEmail(record.row.user?.email);
                setPhoneNumber(record.row.user?.phone_number);
                setDistrictId(record.row.user?.district_id);
                setName(record.row.name);
                setDoctorName(record.row.doctor_name);
                setDoctorPhoneNumber(record.row.doctor_phone_number);
                setDoctorEmail(record.row.doctor_email);
                setAddress(record.row.address);
                setSecretaryName(record.row.secretary_name);
                setSecretaryPhoneNumber(record.row.secretary_phone_number);
                setSecretaryEmail(record.row.secretary_email);
                setLocation(record.row.location);
                setLandlineNumber(record.row.landline_number);
                setWebsite(record.row.website);
                setFacebookPage(record.row.facebook_page);
                setInstagramPage(record.row.instagram_page);

                editopenModal();
              }}
            >
              Edit
            </button>
            {localStorage.getItem("role") == "superadmin" && (
              <button
                data-toggle="modal"
                data-target="#delete-carousel-modal"
                className="actions1"
                onClick={() => {
                  setId(record.row.id);
                  openModal();
                }}
              >
                Delete
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    if (
      email &&
      phone_number &&
      district_id &&
      name &&
      doctor_name &&
      doctor_phone_number &&
      doctor_email &&
      address &&
      emailRegex.test(email)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phone_number, district_id, name, doctor_name, doctor_phone_number, doctor_email,address]);
  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Modal
            isOpen={edutmodalIsOpen}
            onRequestClose={editcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >      

            <div>
              <h2 className="modaltitle">Add Clinic</h2>
            
              <form>
                <div className="modal-body modalview">
                  <div className="row formRow inputstyle">
                    <h6>Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={email}
                      placeholder="Enter the Email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={phone_number}
                      placeholder="Enter the Phone Number"
                      required
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </div>

                  {/* District ID */}
                  <div className="row formRow inputstyle">
                    <h6>District id:</h6>

                    <select
                      onChange={(e) => setDistrictId(e.target.value)}
                      required
                      defaultValue={district_id}
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select District
                      </option>
                      {District?.data?.districts?.map((brands) => (
                        <option key={brands.id} value={brands.id}>
                          {brands.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Name */}
                  <div className="row formRow inputstyle">
                    <h6>Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={name}
                      placeholder="Enter the Name"
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Doctor Name */}
                  <div className="row formRow inputstyle">
                    <h6>Doctor Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={doctor_name}
                      placeholder="Enter the Doctor Name"
                      required
                      onChange={(e) => {
                        setDoctorName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Doctor Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Doctor Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={doctor_phone_number}
                      placeholder="Enter the Doctor Phone Number"
                      required
                      onChange={(e) => {
                        setDoctorPhoneNumber(e.target.value);
                      }}
                    />
                  </div>

                  {/* Doctor Email */}
                  <div className="row formRow inputstyle">
                    <h6>Doctor Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={doctor_email}
                      placeholder="Enter the Doctor Email"
                      required
                      onChange={(e) => {
                        setDoctorEmail(e.target.value);
                      }}
                    />
                  </div>

                  {/* Address */}
                  <div className="row formRow inputstyle">
                    <h6>Address:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={address}
                      placeholder="Enter the Address"
                      required
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>

                  {/* Secretary Name */}
                  <div className="row formRow inputstyle">
                    <h6>Secretary Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={secretary_name}
                      placeholder="Enter the Secretary Name"
                      required
                      onChange={(e) => {
                        setSecretaryName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Secretary Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Secretary Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={secretary_phone_number}
                      placeholder="Enter the Secretary Phone Number"
                      required
                      onChange={(e) => {
                        setSecretaryPhoneNumber(e.target.value);
                      }}
                    />
                  </div>

                  {/* Secretary Email */}
                  <div className="row formRow inputstyle">
                    <h6>Secretary Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={secretary_email}
                      placeholder="Enter the Secretary Email"
                      required
                      onChange={(e) => {
                        setSecretaryEmail(e.target.value);
                      }}
                    />
                  </div>

                  {/* Location */}
                  <div className="row formRow inputstyle">
                    <h6>Location:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={location}
                      placeholder="Enter the Location"
                      required
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Landline Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={landline_number}
                      placeholder="Enter the Landline Number"
                      required
                      onChange={(e) => {
                        setLandlineNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Website:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={website}
                      placeholder="Enter the website"
                      required
                      onChange={(e) => {
                        setWebsite(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Instagram:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={instagram_page}
                      placeholder="Enter the Instagram"
                      required
                      onChange={(e) => {
                        setInstagramPage(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>FaceBook:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={facebook_page}
                      placeholder="Enter the Facebook"
                      required
                      onChange={(e) => {
                        setFacebookPage(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={editcloseModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  editplanhandle(
                    id,
                    email,
                    phone_number,
                    district_id,
                    name,
                    doctor_name,
                    doctor_phone_number,
                    doctor_email,
                    address,
                    secretary_name,
                    secretary_phone_number,
                    secretary_email,
                    location,
                    landline_number,
                    website,
                    facebook_page,
                    instagram_page
                  )
                }
                className="buttoninmodal1"
              >
                Edit clinc
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={addmodalIsOpen}
            onRequestClose={addcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add Clinic</h2>
              <form>
                <div className="modal-body modalview">
                  <div className="row formRow inputstyle">
                    <h6>Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={email}
                      placeholder="Enter the Email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  {/* Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={phone_number}
                      placeholder="Enter the Phone Number"
                      required
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </div>

                  {/* District ID */}
                  <div className="row formRow inputstyle">
                    <h6>District id:</h6>

                    <select
                      onChange={(e) => setDistrictId(e.target.value)}
                      required
                      defaultValue=""
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select District
                      </option>
                      {District?.data?.districts?.map((brands) => (
                        <option key={brands.id} value={brands.id}>
                          {brands.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Name */}
                  <div className="row formRow inputstyle">
                    <h6>Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={name}
                      placeholder="Enter the Name"
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Doctor Name */}
                  <div className="row formRow inputstyle">
                    <h6>Doctor Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={doctor_name}
                      placeholder="Enter the Doctor Name"
                      required
                      onChange={(e) => {
                        setDoctorName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Doctor Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Doctor Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={doctor_phone_number}
                      placeholder="Enter the Doctor Phone Number"
                      required
                      onChange={(e) => {
                        setDoctorPhoneNumber(e.target.value);
                      }}
                    />
                  </div>

                  {/* Doctor Email */}
                  <div className="row formRow inputstyle">
                    <h6>Doctor Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={doctor_email}
                      placeholder="Enter the Doctor Email"
                      required
                      onChange={(e) => {
                        setDoctorEmail(e.target.value);
                      }}
                    />
                  </div>

                  {/* Address */}
                  <div className="row formRow inputstyle">
                    <h6>Address:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={address}
                      placeholder="Enter the Address"
                      required
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>

                  {/* Secretary Name */}
                  <div className="row formRow inputstyle">
                    <h6>Secretary Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={secretary_name}
                      placeholder="Enter the Secretary Name"
                      required
                      onChange={(e) => {
                        setSecretaryName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Secretary Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Secretary Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={secretary_phone_number}
                      placeholder="Enter the Secretary Phone Number"
                      required
                      onChange={(e) => {
                        setSecretaryPhoneNumber(e.target.value);
                      }}
                    />
                  </div>

                  {/* Secretary Email */}
                  <div className="row formRow inputstyle">
                    <h6>Secretary Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={secretary_email}
                      placeholder="Enter the Secretary Email"
                      required
                      onChange={(e) => {
                        setSecretaryEmail(e.target.value);
                      }}
                    />
                  </div>

                  {/* Location */}
                  <div className="row formRow inputstyle">
                    <h6>Location:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={location}
                      placeholder="Enter the Location"
                      required
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Landline Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={landline_number}
                      placeholder="Enter the Landline Number"
                      required
                      onChange={(e) => {
                        setLandlineNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Website:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={website}
                      placeholder="Enter the website"
                      required
                      onChange={(e) => {
                        setWebsite(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Instagram:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={instagram_page}
                      placeholder="Enter the Instagram"
                      required
                      onChange={(e) => {
                        setInstagramPage(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>FaceBook:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={facebook_page}
                      placeholder="Enter the Facebook"
                      required
                      onChange={(e) => {
                        setFacebookPage(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={addcloseModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  addplanhandle(
                    email,
                    phone_number,
                    district_id,
                    name,
                    doctor_name,
                    doctor_phone_number,
                    doctor_email,
                    address,
                    secretary_name,
                    secretary_phone_number,
                    secretary_email,
                    location,
                    landline_number,
                    website,
                    facebook_page,
                    instagram_page
                  )
                }
                className="buttoninmodal1"
                disabled={disabled}
                style={{
                  backgroundColor: disabled ? "gray" : "red",
                  borderColor: disabled ? "gray" : "red",
                }}
              >
                Add Clinic
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Delete Clinc</h2>
              <h5>Are You Sure You Want To Delete This Clinc ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deleteplanhandle(id)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          <Header
            title="Clinics"
            buttonText="Add Clinic"
            onopen={addopenModal}
            showboth={true}
          />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                zIndex: 0,
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
                zIndex: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                zIndex: 0,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
                zIndex: 0,
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
                zIndex: 0,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
                zIndex: 0,
              },
            }}
          >
  <NotificationContainer />

            {loading && <Loading />}
            {!loading && data && (
              
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Clinic;
