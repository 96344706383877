import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Patient: [],
  addPatient: [],
  updatePatient: [],
  deletePatient: [],
  getpatientFiles : [],
  addfile: [],
  addimage: [],
  patientid : []
};

const PatientSlice = createSlice({
  name: "Patient",
  initialState,
  reducers: {
    PatientRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    PatientSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Patient = action.payload;
    },

    PatientFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addPatientRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addPatient = [];
    },
    addPatientSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addPatient = action.payload;
    },

    addPatientFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addPatient = [];

    },
    updatePatientRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updatePatientSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updatePatient = action.payload;
    },

    updatePatientFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletePatientRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deletePatientSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deletePatient = action.payload;
    },

    deletePatientFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPatientFileRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getPatientFileSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.getpatientFiles = action.payload;
    },
    getpatientFileFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addFileRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addFileSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addfile = action.payload;
    },
    addFileFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addImageRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addImageSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addimage = action.payload;
    },
    addImageFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    patientidRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.patientid = [];
    },
    patientidSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.patientid = action.payload;
    },

    patientidFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.patientid = [];

    },
    
    

    
  },
});

export const PatientAction = PatientSlice.actions;
export default PatientSlice;
