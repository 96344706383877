import React, { useEffect, useState, Fragment } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { getLab, deleteLab, updateLab, AddLab } from "../../Redux/Lab/LabActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Modal from "react-modal";
import PopupModal from "../../components/PopupModal";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import isAuth from "../Utils/isAuth";
import { useNavigate } from "react-router-dom";
import { getDistrict } from "../../Redux/District/DistrictActions";

const Lab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  const { Lab, loading } = useSelector((state) => state.Lab);
  useEffect(() => {
    dispatch(getLab());
    dispatch(getDistrict());


  }, [dispatch]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (Lab) {
      setData(Lab?.data?.data);
    }
  }, [Lab]);
  const { District } = useSelector((state) => state.District);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [district_id, setDistrict_id] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const deleteLabHandle = (id) => {
    dispatch(deleteLab(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
console.log(district_id)
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setEmail("");
    setPhone_number("");
    setDistrict_id("");
    setName("");
    setAddress("");
  };

  const addLabHandle = (email, phone_number, district_id, name, address) => {
    dispatch(
      AddLab(email, phone_number, district_id, name, address)
    );
    setAddModalIsOpen(false);
    setEmail("");
    setPhone_number("");
    setDistrict_id("");
    setName("");
    setAddress("");
  };

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setEmail("");
    setPhone_number("");
    setDistrict_id("");
    setName("");
    setAddress("");
  };

  const editLabHandle = (id, email, phone_number, district_id, name, address) => {
    dispatch(
      updateLab(id, email, phone_number, district_id, name, address)
    );
    setEditModalIsOpen(false);
    setEmail("");
    setPhone_number("");
    setDistrict_id("");
    setName("");
    setAddress("");
    setId("");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    { field: "name", headerName: "Name" },
    {
      field: "email",
      headerName: "Email",
      renderCell: (params) => {
        // Assuming params.row.source_id is the actual value from your data
        const sourceId = params.row.user?.email || "No Email";
  
        return (
        <div>
          {sourceId}

        </div>
        );
      },
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      renderCell: (params) => {
        // Assuming params.row.source_id is the actual value from your data
        const sourceId = params.row.user?.phone_number || "No Phone Number";
  
        return (
        <div>
          {sourceId}

        </div>
        );
      },
    },
    {
      field: "district_id",
      headerName: "District",
      renderCell: (params) => {
        // Assuming params.row.source_id is the actual value from your data
        const sourceId = params.row.district?.name || "No District";
  
        return (
        <div>
          {sourceId}

        </div>
        );
      },
    },

    { field: "address", headerName: "Address" },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (record) => (
        <Fragment>
          <button
            className="actions"
            style={{ marginRight: "5px" }}
            onClick={() => {
              setId(record.row.id);
              setEmail(record.row.user?.email);
              setPhone_number(record.row.user?.phone_number);
              setDistrict_id(record.row.user.district_id);
            
              setName(record.row.name);
              setAddress(record.row.address);
              openEditModal();
            }}
          >
            Edit
          </button>
       { localStorage.getItem("role") == "superadmin"  &&   <button
            className="actions1"
            onClick={() => {
              setId(record.row.id);
              openModal();
            }}
          >
            Delete
          </button>}
        </Fragment>
      ),
    },
  ];

  const [isSidebar, setIsSidebar] = useState(true);
console.log(Lab)
  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Modal
            isOpen={editModalIsOpen}
            onRequestClose={closeEditModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
      
            <form>
            <h2 className="modaltitle">Edit Lab</h2>
            <div className="modal-body modalview">

            <div className="row formRow inputstyle">
           
              <h6>Email:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={email}
                placeholder="Enter Email"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="row formRow inputstyle">
              <h6>Phone Number:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={phone_number}
                placeholder="Enter Phone Number"
                required
                onChange={(e) => {
                  setPhone_number(e.target.value);
                }}
              />
            </div>
            <div className="row formRow inputstyle">
                    <h6>District id:</h6>

                    <select
                      onChange={(e) => setDistrict_id(e.target.value)}
                      required
                      defaultValue={district_id}
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select District
                      </option>
                      {District?.data?.districts?.map((brands) => (
                        <option key={brands.id} value={brands.id}>
                          {brands.name}
                        </option>
                      ))}
                    </select>
                  </div>
            <div className="row formRow inputstyle">
              <h6>Name:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={name}
                placeholder="Enter Name"
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="row formRow inputstyle">
              <h6>Address:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={address}
                placeholder="Enter Address"
                required
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
            </div>
          </form>
          <div
            style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <button onClick={closeEditModal} className="buttoninmodal">
              Close
            </button>
            <button
              onClick={() =>
                editLabHandle(id, email, phone_number, district_id, name, address)
              }
              className="buttoninmodal1"
            >
              Edit Lab
            </button>
          </div>
          </Modal>
          <Modal
             isOpen={addModalIsOpen}
             onRequestClose={closeAddModal}
             shouldCloseOnOverlayClick={false}
             contentLabel="Add modal"
             style={{
               overlay: {
                 backgroundColor: "rgba(0, 0, 0, 0.5)",
               },
               content: {
                 width: "40%",
 
                 margin: "0 auto",
                 padding: "10px",
                 background: "white",
                 borderRadius: "8px",
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "space-between",
                 zIndex: 1000,
               },
             }}
          >
            <form>
            <h2 className="modaltitle"> Add Lab</h2>
            <div className="modal-body modalview">
            <div className="row formRow inputstyle">
              <h6>Email:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={email}
                placeholder="Enter Email"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="row formRow inputstyle">
              <h6>Phone Number:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={phone_number}
                placeholder="Enter Phone Number"
                required
                onChange={(e) => {
                  setPhone_number(e.target.value);
                }}
              />
            </div>
            <div className="row formRow inputstyle">
                    <h6>District id:</h6>

                    <select
                      onChange={(e) => setDistrict_id(e.target.value)}
                      required
                      defaultValue={district_id}
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select District
                      </option>
                      {District?.data?.districts?.map((brands) => (
                        <option key={brands.id} value={brands.id}>
                          {brands.name}
                        </option>
                      ))}
                    </select>
                  </div>
            <div className="row formRow inputstyle">
              <h6>Name:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={name}
                placeholder="Enter Name"
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="row formRow inputstyle">
              <h6>Address:</h6>
              <input
                type="text"
                className="inputt col-md-8 form-control cat"
                value={address}
                placeholder="Enter Address"
                required
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
            </div>
          </form>
          <div
            style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <button onClick={closeAddModal} className="buttoninmodal">
              Close
            </button>
            <button
              onClick={() =>
                addLabHandle(email, phone_number, district_id, name, address)
              }
              className="buttoninmodal1"
            >
              Add Lab
            </button>
          </div>
          </Modal>
          <Modal
             isOpen={modalIsOpen}
             onRequestClose={closeModal}
             shouldCloseOnOverlayClick={false}
             contentLabel="delete modal"
             style={{
               overlay: {
                 backgroundColor: "rgba(0, 0, 0, 0.5)",
               },
               content: {
                 width: "40rem",
                 height: "15rem",
                 margin: "0 auto",
                 padding: "10px",
                 background: "white",
                 borderRadius: "8px",
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "space-between",
                 zIndex: 1000,
               },
             }}
          >
            <h2>Delete Lab</h2>
            <h5>Are you sure you want to delete this lab?</h5>
            <div       style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}> 
              <button onClick={closeModal} className="buttoninmodal">Close</button>
              <button onClick={() => deleteLabHandle(id)}                 className="buttoninmodal1"
>Delete Lab</button>
            </div>
          </Modal>
          <Header title="Labs" buttonText="Add Lab" onopen={openAddModal} showboth={true}/>
      


          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                zIndex: 0,
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
                zIndex: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                zIndex: 0,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
                zIndex: 0,
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
                zIndex: 0,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
                zIndex: 0,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Lab;
