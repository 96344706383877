import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { getcontactus } from "../../Redux/Contactus/ContactActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import PopupModal from "../../components/PopupModal";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";
import isAuth from "../../scenes/Utils/isAuth";
import { useNavigate } from "react-router-dom";

const Contactus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);
  const { contactus, loading } = useSelector((state) => state.Contact);
  useEffect(() => {
    dispatch(getcontactus());
  }, []);
  const [data, setData] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);

  useEffect(() => {
    if (contactus) {
      setData(contactus?.data?.contactUs);
    }
  }, [contactus]);
  console.log(data);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const openMessage = (e,message) => {
    e.preventDefault();
    alert(message);
  }
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    { field: "message", headerName: "Message" ,width :300},
    {
      field: "First Name",
      headerName: "First Name",
      valueGetter: (params) => params.row.patient.first_name,
      flex: 1,
    },
    {
      field: "Last Name",
      headerName: "Last Name",
      valueGetter: (params) => params.row.patient.last_name,
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gender",
      valueGetter: (params) => params.row.patient.gender,
      flex: 1,
    },
    {
      
        field: "Oepn message",
        headerName: "Oepn message",
        flex: 1,
        renderCell: (params) => (
          <button
            variant="outlined"
            color="primary"
            onClick={(e) => openMessage(e,params.row.message)}
          >
           Open Message
          </button>
        ),
      

    },

    {
      field: "teeth modal",
      headerName: "Teeth Modal",
      flex: 1,
      renderCell: (params) => (
        <button
          variant="outlined"
          color="primary"
          onClick={() => window.open(params.row.patient.teeth_model, "_blank")}
        >
          Open Teeth Modal
        </button>
      ),
    },
  ];

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Header title="Contact us" show={true} showboth={true}/>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Contactus;
