import BookOnlineIcon from "@mui/icons-material/BookOnline";
import DomainIcon from "@mui/icons-material/Domain";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getstatistics } from "../../Redux/Auth/AuthActions";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import isAuth from "../../scenes/Utils/isAuth";
import { tokens } from "../../theme";
import Sidebar from "./../global/Sidebar";
import Topbar from "./../global/Topbar";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bytime, setbytime] = useState("month");
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);
  const [Statistic, setstatistic] = useState([]);

  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  useEffect(() => {
    dispatch(getstatistics());
  }, [dispatch]);

  const { getstatistic } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (getstatistic) {
      setstatistic(getstatistic?.data);
    }
  }, [getstatistic]);

  console.log("stattttttt", Statistic);
  const handleSelectChange = (e) => {
    setbytime(e.target.value); // Update the state with the selected value
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="DASHBOARD" show={true} showboth={true} />
            <button
              onClick={(e) => {
                if (bytime == "month") {
                  setbytime("year");
                } else {
                  setbytime("month");
                }
              }}
            >
              {bytime == "month" ? "This Month" : "This Year"}
            </button>
          </Box>

          {/* GRID & CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            {/* ROW 1 */}
            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={
                    bytime == "month"
                      ? Statistic?.appointmentsThisMonth
                      : Statistic?.appointmentsLastYear
                  }
                  subtitle="Total Appointments"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <BookOnlineIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {localStorage.getItem("role") == "clinic" && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={
                    bytime == "month"
                      ? Statistic?.appointmentsThisMonth
                      : Statistic?.appointmentsLastYear
                  }
                  subtitle="Appointments"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <BookOnlineIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={
                    bytime == "month"
                      ? Statistic?.patientsThisMonth
                      : Statistic?.patientsLastYear
                  }
                  subtitle="Total patients"
                  icon={
                    <PointOfSaleIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {localStorage.getItem("role") == "clinic" && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={
                    bytime == "month"
                      ? Statistic?.patientsThisMonth
                      : Statistic?.patientsLastYear
                  }
                  subtitle="Patients"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <BookOnlineIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {localStorage.getItem("role") == "clinic" && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={Statistic?.appointments}
                  subtitle="Total Appointments"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <BookOnlineIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {localStorage.getItem("role") == "clinic" && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={Statistic?.patients}
                  subtitle="Total Patients "
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <BookOnlineIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={
                    bytime == "month"
                      ? Statistic?.totalPatientByFixalignThisMonth
                      : Statistic?.totalPatientByFixalignLastYear
                  }
                  subtitle="Patients By Fixalign"
                  icon={
                    <DomainIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}
            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={
                    bytime == "month"
                      ? Statistic?.totalPatientByClinicsThisMonth
                      : Statistic?.totalPatientByClinicsLastYear
                  }
                  subtitle="Patients By Clinic"
                  icon={
                    <LocalShippingIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}

            {/* ROW 2 */}
            <Box
              gridColumn="span 8"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Box
                mt="25px"
                p="0 30px"
                display="flex "
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Statistics
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={true} stat={Statistic} />
              </Box>
            </Box>
            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                overflow="auto"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="15px"
                >
                  <Typography
                    color={colors.grey[100]}
                    variant="h5"
                    fontWeight="600"
                  >
                    Unassigned Apppointment
                  </Typography>
                </Box>
                {Statistic?.notApprovedAppointments?.map((transaction, i) => (
                  <Box
                    key={`${transaction.patient?.district?.name}-${i}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      ></Typography>
                      {transaction.patient?.district?.name}
                      <Typography color={colors.grey[100]}>
                        <ol>
                          {transaction?.patient?.patients?.map((patient) => (
                            <li key={patient.id}>
                              {" "}
                              {/* Add a unique key */}
                              <i>
                                {patient.first_name} {patient.last_name}
                              </i>
                              <br />
                            </li>
                          ))}
                        </ol>
                      </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>
                      {" "}
                      {transaction.patient?.phone_number}
                    </Box>
                    <Box
                      backgroundColor={colors.greenAccent[500]}
                      p="5px 10px"
                      borderRadius="4px"
                    >
                      {moment(transaction.patient.created_at).format(
                        "DD/MM/YYYY"
                      )}{" "}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={Statistic?.ongoningPatient}
                  subtitle="OnGoing Patients"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <CallMissedOutgoingIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}
            {(localStorage.getItem("role") == "admin" ||
              localStorage.getItem("role") == "superadmin") && (
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={Statistic?.finishedPatient}
                  subtitle="Completed Patients"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <CheckIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Dashboard;
