import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  getplan,
  deleteplan,
  updateplan,
  Addplan,
} from "./../../Redux/Plan/PlanActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import PopupModal from "../../components/PopupModal";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";
import isAuth from "../../scenes/Utils/isAuth";
import { useNavigate } from "react-router-dom";

const Plan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);
  const { plan, loading } = useSelector((state) => state.Plan);
  useEffect(() => {
    dispatch(getplan());
  }, []);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (plan) {
      setData(plan?.data?.plans);
    }
  }, [plan]);
  console.log(data);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [price, setPrice] = useState("");
  const [number_of_treatments, setNumber_of_treatments] = useState("");
  const deleteplanhandle = (id) => {
    dispatch(deleteplan(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setNumber_of_treatments("");
    setPrice("");
    setDescription3("");
    setDescription2("");
    setDescription1("");
    setTitle("");
  };
  const addplanhandle = (
    title,
    description1,
    description2,
    description3,
    price,
    number_of_treatments
  ) => {
    dispatch(
      Addplan(
        title,
        description1,
        description2,
        description3,
        parseInt(price), // If needed, use parseInt here
        parseInt(number_of_treatments) // If needed, use parseInt here
      )
    );
    setaddModalIsOpen(false);
    setNumber_of_treatments("");
    setPrice("");
    setDescription3("");
    setDescription2("");
    setDescription1("");
    setTitle("");
  };

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
    setNumber_of_treatments("");
    setPrice("");
    setDescription3("");
    setDescription2("");
    setDescription1("");
    setTitle("");
  };
  const editplanhandle = (
    id,
    title,
    description1,
    description2,
    description3,
    price,
    number_of_treatments
  ) => {
    dispatch(
      updateplan(
        id,
        title,
        description1,
        description2,
        description3,
        parseInt(price), // If needed, use parseInt here
        parseInt(number_of_treatments) // If needed, use parseInt here
      )
    );
    setedutModalIsOpen(false);
    setNumber_of_treatments("");
    setPrice("");
    setDescription3("");
    setDescription2("");
    setDescription1("");
    setTitle("");
    setId("");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    { field: "title", headerName: "Name" },
    {
      field: "number_of_treatments",
      headerName: "Number of Treatments",
    },
    {
      field: "description1",
      headerName: "Description 1",

      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "description2",
      headerName: "Description 2",
      cellClassName: "name-column--cell",

      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "description3",
      headerName: "Description 3",
      cellClassName: "name-column--cell",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setTitle(record.row.title);
                setDescription1(record.row.description1);
                setDescription2(record.row.description2);
                setDescription3(record.row.description3);
                setPrice(record.row.price);
                setNumber_of_treatments(record.row.number_of_treatments);
                editopenModal();
              }}
            >
              Edit
            </button>
    { localStorage.getItem("role") == "superadmin"  &&        <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                openModal();
              }}
            >
              Delete
            </button>}
          </Fragment>
        );
      },
    },
  ];
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Modal
            isOpen={edutmodalIsOpen}
            onRequestClose={editcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add Plan</h2>
              <form>
                <div className="modal-body modalview">
                  <div className="row formRow inputstyle">
                    <h6>Title:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={title}
                      placeholder="Enter the Title"
                      required
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>Description one:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={description1}
                      placeholder="Enter the First Description"
                      required
                      onChange={(e) => {
                        setDescription1(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Description Two:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={description2}
                      placeholder="Enter the Second Description"
                      required
                      onChange={(e) => {
                        setDescription2(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Description Three:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={description3}
                      placeholder="Enter the Third Description"
                      required
                      onChange={(e) => {
                        setDescription3(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>price:</h6>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={price}
                      placeholder="Enter the Price"
                      required
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>Number of treatments:</h6>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={number_of_treatments}
                      placeholder="Enter the Number of Treatments"
                      required
                      onChange={(e) => {
                        setNumber_of_treatments(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={editcloseModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  editplanhandle(
                    id,
                    title,
                    description1,
                    description2,
                    description3,
                    price,
                    number_of_treatments
                  )
                }
                className="buttoninmodal1"
              >
                Edit Plan
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={addmodalIsOpen}
            onRequestClose={addcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add Plan</h2>
              <form>
                <div className="modal-body modalview">
                  <div className="row formRow inputstyle">
                    <h6>Title:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={title}
                      placeholder="Enter the Title"
                      required
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>Description one:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={description1}
                      placeholder="Enter the First Description"
                      required
                      onChange={(e) => {
                        setDescription1(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Description Two:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={description2}
                      placeholder="Enter the Second Description"
                      required
                      onChange={(e) => {
                        setDescription2(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Description Three:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={description3}
                      placeholder="Enter the Third Description"
                      required
                      onChange={(e) => {
                        setDescription3(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>price:</h6>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={price}
                      placeholder="Enter the Price"
                      required
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>Number of treatments:</h6>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={number_of_treatments}
                      placeholder="Enter the Number of Treatments"
                      required
                      onChange={(e) => {
                        setNumber_of_treatments(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={addcloseModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  addplanhandle(
                    title,
                    description1,
                    description2,
                    description3,
                    price,
                    number_of_treatments
                  )
                }
                className="buttoninmodal1"
              >
                Add Plan
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Delete Plan</h2>
              <h5>Are You Sure You Want To Delete This Plan ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deleteplanhandle(id)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          <Header title="Plan" buttonText="Add Plan" onopen={addopenModal} showboth={true}/>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Plan;
